import * as React from 'react'
import * as DateFns from 'date-fns'
import { Link } from 'react-router-dom'
import Box from 'common/Box/index.js'
import Format from 'util/format/Format'
import DataGrid from 'common/DataGrid'

import LinearProgress from 'common/LinearProgress'
import DataGridFooter from 'common/DataGridFooter'
import DataGridToolbar from 'common/DataGridToolbar'
import Typography from 'common/Typography'
import { GridNoneFound } from 'common/NoneFound'
import {
    removeReactFromColumns,
    addReactToColumns,
    syncVisibilityModel,
} from 'common/DataGrid/utils/column-utils'
import useQuery from 'delv/hooks/use-query.js'
import useArgs from 'hooks/UseArgs'

import useLocalStorage from 'hooks/use-local-storage'
import PageLayout from 'layouts/page-layout'
import { argsToString } from 'delv/services/query-arg-service'

import {
    CurrencyCell,
    DateCell,
    NumberCell,
    PercentCell,
    TooltipCell,
} from 'common/DataGridCell'

const MaybePercentCell = ({ value }) => (value === undefined ? 'N/A' : PercentCell({ value }))
const MaybeTermCell = ({ value }) => (value === undefined ? 'N/A' : NumberCell({ value }))

// query made for this page specifically
const HOLDINGS_QUERY = (args) => `
{
    allFullHoldings${args} {
        totalCount
        nodes {
            __typename
            id
            amount
            purchasePrice
            investorAccountName
            investorAccountEmail
            percentOfSecurity
            totalValue
            issuedOn
            chain
            term
            coupon
            securityName
        }
    }
}`

const BREADCRUMBS = [
    {
        display: 'Dashboard',
        link: '/dashboard',
        active: false,
    },
    {
        display: 'Holdings',
        link: '/holdings',
        active: true,
    },
]

const columnDefaults = {
    hideable: true,
    flex: 1,
    minWidth: 100,
    filterable: true,
}

const numberColumnDefaults = {
    ...columnDefaults,
    align: 'right',
}

const initialColumns = [
    {
        ...columnDefaults,
        field: 'investorAccountName',
        headerName: 'Investor',
        minWidth: 150,
        renderCell: ({ value }) => (
            <Typography variant='subtitle2'>
                {value}
            </Typography>
        ),
    },
    {
        ...columnDefaults,
        field: 'investorAccountEmail',
        headerName: 'Investor Email',
        minWidth: 150,
    },
    {
        ...columnDefaults,
        field: 'securityName',
        headerName: 'Security Name',
        minWidth: 150,
    },
    {
        ...numberColumnDefaults,
        field: 'amount',
        headerName: 'Total Units',
        minWidth: 150,
        valueFormatter: ({ value }) => value.amount,
        renderCell: ({ value }) => (
            <Box className='row' sx={{ gap: 1 }}>
                <Link className='pointer' to={`/holding/${value.id}`}>
                    <Typography variant='body2'>
                        {Format.number(value.amount)}
                    </Typography>
                </Link>
            </Box>
        ),
    },
    {
        ...numberColumnDefaults,
        field: 'percentOfSecurity',
        headerName: 'Percent Of Raise',
        minWidth: 150,
        renderCell: MaybePercentCell,
        renderHeader: (props) => (
            <TooltipCell tooltip="Calculated by dividing the holding's quantity of shares by the security's authorized total." {...props} />
        ),
    },
    {
        ...numberColumnDefaults,
        field: 'purchasePrice',
        headerName: 'Cost Basis',
        minWidth: 150,
        renderCell: CurrencyCell,
        renderHeader: (props) => <TooltipCell tooltip='Price paid per share' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'isTokenized',
        headerName: 'Tokenized',
        type: 'boolean',
        minWidth: 75,
    },
    {
        ...numberColumnDefaults,
        field: 'term',
        headerName: 'Term',
        renderCell: MaybeTermCell,
        renderHeader: (props) => <TooltipCell tooltip='Years to maturity' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'coupon',
        headerName: 'Yield',
        renderCell: MaybePercentCell,
    },
    {
        ...numberColumnDefaults,
        field: 'totalValue',
        headerName: 'Total Value',
        renderCell: CurrencyCell,
        renderHeader: (props) => <TooltipCell tooltip='Quantity of shares * share price' {...props} />,
    },
    {
        ...numberColumnDefaults,
        field: 'issuedOn',
        headerName: 'Issued On',
        renderCell: DateCell,
    },
]

const format = (data) => {
    const allFullHoldings = data.allFullHoldings.nodes

    const rows = allFullHoldings.map((i) => ({
        ...i,
        amount: {
            amount: i.amount,
            id: i.id,
        },
        isTokenized: i.chain !== 'vertalon-hawknet',
    }))
    return { rows, totalCount: data.allFullHoldings.totalCount }
}

const initialColumnVisibilityModel = {
    term: false,
    yield: false,
    isTokenized: false,
}

const Holdings = (props) => {
    const argUtils = useArgs({ args: props.args })
    const { data, loading, error } = useQuery(HOLDINGS_QUERY, {
        args: argsToString(argUtils.args),
        format,
    })
    const [density, setDensity] = useLocalStorage('row-density', 'compact')
    const [columns, setColumns] = useLocalStorage('holdings-columns', initialColumns, {
        serialize: removeReactFromColumns,
        deserialize: addReactToColumns(initialColumns),
    })
    const [columnVisibilityModel, setColumnVisibilityModel] = useLocalStorage('holdings-column-visibility', initialColumnVisibilityModel, {
        deserialize: syncVisibilityModel(initialColumnVisibilityModel),
    })

    const rows = data?.rows || []

    const resetGrid = () => {
        setColumns(initialColumns)
        setColumnVisibilityModel(initialColumnVisibilityModel)
    }

    return (
        <PageLayout breadcrumbs={BREADCRUMBS}>
            <DataGrid
                disableColumnResize={false}
                disableColumnPinning
                onColumnOrderChange={(e) => {
                    const target = e.targetIndex
                    const old = e.oldIndex
                    const newColumns = [...columns]
                    newColumns[target] = columns[old]
                    newColumns[old] = columns[target]
                    setColumns(newColumns)
                }}
                error={error}
                rows={rows}
                columns={columns}
                loading={loading}
                rowThreshold={0}
                density={density}
                totalCount={data?.totalCount}
                onColumnWidthChange={(e) => {
                    const newColumns = [...columns].map((col) => (
                        col.field === e.colDef.field
                            ? ({
                                ...col,
                                flex: undefined,
                                width: e.colDef.width,
                            }) : col
                    ))
                    setColumns(newColumns)
                }}
                onColumnVisibilityModelChange={(newModel) => setColumnVisibilityModel(newModel)}
                onStateChange={(params) => {
                    if (params.density.value !== density) {
                        setDensity(params.density.value)
                    }
                }}
                sx={{
                    border: 'none',
                    backgroundColor: '#ffffff',
                    '& .Mui-selected': {
                        background: 'transparent !important',
                    },
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                    Footer: DataGridFooter,
                    NoRowsOverlay: GridNoneFound,
                    Toolbar: DataGridToolbar,
                }}
                componentsProps={{
                    noRowsOverlay: {
                        children: 'No Holdings Found',
                    },
                    toolbar: {
                        resetGrid,
                        filter: true,
                        export: true,
                        printOptions: {
                            disableToolbarButton: true,
                        },
                        csvOptions: {
                            fileName: `holdings_vertalo_${DateFns.format(new Date(), 'yyyy_MM_dd')}`,
                        },
                    },
                    footer: {
                        rows,
                        totalCount: data?.totalCount,
                    },
                }}
                columnVisibilityModel={columnVisibilityModel}
                {...argUtils}
                {...props}
            />
        </PageLayout>
    )
}

export default Holdings
